( () => {
  const leadershipSection = document.querySelectorAll( '.e-stn--leadership-cards' );
  const leadershipSectionNew = document.querySelectorAll( '.e-stn--leadership-cards-layout' );
  if ( leadershipSection.length === 0 || leadershipSectionNew.length != 0 ) return;

  document.addEventListener( 'facetwp-loaded', () => {
    leadershipSection.forEach( section => {
      const items = section.querySelectorAll( '.b3rg-posts > .e-row > .e-col' );
      section.querySelector( '.b3rg-posts' )?.classList.add( `items-on-last-row-${items.length % 4 || 4}` );

      if ( section.classList.contains( 'e-stn--leadership-cards-v2' ) ) {
        items.forEach( ( item, index ) => {
          item.classList.add( `item-num-${( index % 4 ) + 1}` );
        } );
      }
    } );
  } );
} )();
